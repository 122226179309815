<template>
	<div class="container">
		<el-page-header @back="goBack" content="修改">
		</el-page-header>
		<div class="cont-body" v-loading="searchLoading">
			<div class="content">
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">点击选择话题</div>
					</div>
					<div class="ctx-item-b" style="text-align: right;">
						<label style="color: blue;text-decoration: underline;cursor: pointer;" @click="dialogFormVisible = true">自定义 >>></label>
					</div>
				</div>
				<div class="ctx-item">
					<div class="flex-start flex-warp">
						<label v-for="(item,index) in hotCodes" @click="addTopic(item)" class="label_hot_item">
							#{{item.shc_code_itemname}}#
						</label>
					</div>
				</div>
				<div class="ctx-item" v-if="labelList.length > 0" style="justify-content: flex-start;">
					<div class="flex-start flex-warp">
						<label v-for="(item,index) in labelList" :key="item.labelCodeId" @click="delPickedTopic(item)" style="color: #a13e09;margin: 0 10px 10px 0;cursor: pointer;">
							#{{item.labelCodeName}}#
						</label>
					</div>
				</div>
				<el-dialog title="自定义话题" width="30%" :visible.sync="dialogFormVisible">
				  <el-form>
				    <el-form-item label="" >
				      <el-input v-model="selfTopic" maxlength="30" placeholder="输入自定义话题"></el-input>
				    </el-form-item>
				  </el-form>
				  <div slot="footer" class="dialog-footer">
				    <el-button @click="dialogFormVisible = false">取 消</el-button>
				    <el-button type="primary" @click="addTopicPop()">确 定</el-button>
				  </div>
				</el-dialog>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">标题<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" >
						<el-input placeholder="请输入内容标题" v-model="recommendTitle" maxlength="30" />
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">正文<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b">
						<el-input type="textarea" v-model="reqNail" :autosize="{ minRows: 4}" maxlength="200" placeholder="输入正文" show-word-limit></el-input>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">所在地址<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" >
						<el-input placeholder="请输入推荐所在地点" v-model="recommendPlace" maxlength="80" />
					</div>
				</div>
				<div class="ctx-item inlinediv">
					<pickContact ref="pickContact" :queryUid="userInfo.fayanboId" :baseUrlpath="baseUrlpath" @changeConcactShow="changeConcactShow" :isShowConcact="isShowConcact" :isDetail="isDetail"></pickContact>
				</div>

				<div class="ctx-item marb200">
					<div class="ctx-item-t">
						<div class="ctx-item-head">上传照片/视频<label class="bishu">*</label></div>
						<div class="ctx-item-headdesc"><label>({{finalFileList.length}}/{{limitFilesNum}})</label></div>
					</div>
					<div class="ctx-item-b">
						<uploadFile  v-if="fileList.length > 0" :baseUrlpath="baseUrlpath" :fileList="fileList" @addOrMulitiFileList="addOrMulitiFileList"></uploadFile>
					</div>
				</div>
				<!-- <div class="ctx-item">
					<div class="ctx-item-b">
						<div class="pub_jd_cost">
							<div class="jd_total">
								<label class="unit">共需花费</label>
								<label class="num">{{pubTotalCost}}</label>
								<label class="unit">金豆</label>
							</div>
							<div class="cost_desc">
								<label>发布</label>
								<label class="num">{{pubNeedCost}}</label>
								<label>金豆</label>
								<template v-if="tickTopOrNot">
									<label>+</label>
									<label>置顶</label>
									<label class="num">{{tickTopNeedCost}}</label>
									<label>金豆</label>
								</template>
							</div>
						</div>
					</div>
				</div> -->
				<div class="btn-foot">
					<div class="btn" @click="publish()">修改</div>
				</div>
			</div>	
		</div>
	</div>
</template>

<script>
	import {mapGetters,mapActions} from 'vuex'
	import uploadFile from '@/components/upload/uploadFile.vue'
	import pickContact from '@/components/pickContact.vue'
	import {queryUrl,doPost,commitFileUrl,queryUrlWithUInfo,postWithUInfo} from '@/api/index';
	import { Toast } from 'mint-ui';
	export default {
		components:{
		  uploadFile,pickContact
		},
		data(){
			return {
				selfTopic:'',
				dialogFormVisible:false,
				labelList:[],
				hotCodes:[],
				reqType:'',
				multiArray:[],
				array:[],
				citysList:[],
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				experienceArr:[],
				experienceIndex:'0',
				recommendType: '0',
				recommendTitle:'',
				recommendPlace:'',
				reqNail:'',
				fileList:[],
				finalFileList:[],
				limitFilesNum:9,
				baseUrlpath:'',
				delicacyTags:[],
				restaurantName:'',
				reqNo:'',
				isShowConcact:false,
				tickTopOrNot:false,
				tickTopNeedCost:0,
				pubNeedCost:0,
				pubTotalCost:0,
				isDetail:false,
				searchLoading:false,
				sdr_userId:''
			}
		},
		created() {
			// console.log(this.$route.query)
			this.reqNo = this.$route.query.reqNo;
			this.citysList = this.$util.citysList;
			this.experienceArr = this.$util.experienceLebels;
			this.delicacyTags = this.$util.recommendLebels;
			this.getHotCodeTop();
			this.queryTickJdCost()
			this.queryPubJdCost()
			this.getCommentInfoByReqNo()
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		watch:{
			tickTopNeedCost:{
				handler(val, oldval) {
					if(this.tickTopOrNot){
						this.pubTotalCost = parseInt(val) + parseInt(this.pubNeedCost) 
					}else{
						this.pubTotalCost = parseInt(this.pubNeedCost)
					}
				}
			},
			pubNeedCost:{
				handler(val, oldval) {
					if(this.tickTopOrNot){
						this.pubTotalCost = parseInt(val) + parseInt(this.tickTopNeedCost) 
					}else{
						this.pubTotalCost = parseInt(val)
					}
				}
			},
			tickTopOrNot:{
				handler(val, oldval) {
					console.log(val)
					if(val){
						this.pubTotalCost = parseInt(this.pubNeedCost) + parseInt(this.tickTopNeedCost) 
					}else{
						this.pubTotalCost = parseInt(this.pubNeedCost)
					}
				}
			}
		},
		methods:{
			addTopicPop(){
				if(this.selfTopic.length == 0){
					this.$util.msg('请输入话题');
					return false;
				}
				var uuid = v4()
				this.labelList.push({"labelCodeId":uuid,"labelCodeName":this.selfTopic})
				this.selfTopic = ''
				this.dialogFormVisible = false
			},
			delPickedTopic(topic){
				for(var item in this.labelList){
				  if(this.labelList[item].labelCodeId == topic.labelCodeId){
				      this.labelList.splice(item,1);
				  }
				}
			},
			addTopic(item){
				this.addLabel({itemId:item.shc_code_itemid,itemName:item.shc_code_itemname})
			},
			addLabel(item){
				console.log(item)
				var has = false
				for(var index in this.labelList){
					if(item.itemId === this.labelList[index].labelCodeId){
						has = true;
						break
					}
				}
				if(!has){
					this.labelList.push({"labelCodeId":item.itemId,"labelCodeName":item.itemName})
				}
			},
			getHotCodeTop(){
				var vm = this;
				var params = {};
				if(localStorage.getItem("fayanboAreaId")){
					params.modelId = localStorage.getItem("fayanboAreaId")
				}
				var requestUrl = '/apis/getHotCodeTop'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/cnapis/getHotCodeTop'
				}
				queryUrl(requestUrl, params).then(res => {
					vm.hotCodes = res.itemsList;
				});
			},
			async getCommentInfoByReqNo() {
				this.searchLoading = true
				var requestUrl = '/apis/getRecommendReqInfo'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/cnapis/getRecommendReqInfo'
				}
				const initres = await queryUrlWithUInfo(requestUrl,{reqNo: this.reqNo});
				if (initres.ec == '0') {
					this.reqNail = initres.reqInfo.sdrr_contents;
					this.recommendType = initres.reqInfo.sdrr_recommendType;
					this.experienceIndex = initres.reqInfo.sdrr_experienceIndex;
					this.recommendTitle = initres.reqInfo.sdrr_recommendTitle;
					this.recommendPlace = initres.reqInfo.sdrr_recommendPlace;
					this.isShowConcact = initres.reqInfo.sdrr_isShowConcact == '1' ? true :false;
					this.fileList = [];
					this.finalFileList = [];
					var attachlist = initres.reqInfo.attachlist;
					for(var index in attachlist){
						this.finalFileList.push({fileId:attachlist[index].sdra_fileid,path:attachlist[index].sdra_filePath,url:attachlist[index].sdra_filePath});
					}
					this.fileList = JSON.parse(JSON.stringify(this.finalFileList))
					this.labelList = initres.reqInfo.labellist;
					console.log('修改。。')
					this.searchLoading = false
				}
			},
			tickTopChange(e){
				var needCost = this.tickTopNeedCost;
				var _this = this;
				console.log(e)
				if(e){
					this.$confirm('置顶需花费'+needCost+'金豆'+', 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						
					}).catch(() => {
						_this.tickTopOrNot = false       
					});
				}
			},
			async queryTickJdCost(){
				var requestUrl = '/apis/queryJdNeedCost'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/apis/queryJdNeedCost'
				}
				const initres = await queryUrlWithUInfo(requestUrl,{queryType:'11'});
				if(initres.ec == '0'){
					this.tickTopNeedCost = parseInt( initres.jdCost)
				}
			},
			async queryPubJdCost(){
				var requestUrl = '/apis/pubJdNeedCost'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/apis/pubJdNeedCost'
				}
				const initres = await queryUrlWithUInfo(requestUrl,{queryType:'17'});
				if(initres.ec == '0'){
					this.pubNeedCost = parseInt(initres.jdCost)
				}
			},
			changeConcactShow(val){
				this.isShowConcact = val
			},
			goBack() {
				this.$util.goBack();
			},
			async publish(){
				var vm = this;
				if(this.labelList.length == 0){
					this.$util.msg('请选择话题');
					return false;
				}
				if(this.recommendTitle.length == 0){
					this.$util.msg('请输入推荐内容标题');
					return false;
				}
				if(this.recommendPlace.length == 0){
					this.$util.msg('请输入推荐所在地点');
					return false;
				}
				if(this.reqNail.length == 0){
					this.$util.msg('请输入推荐理由');
					return false;
				}
				if(this.finalFileList.length == 0){
					this.$util.msg('请上传图片');
					return false;
				}
				vm.$util.showMyLoading('加载中');
				var params = {};
				params.reqNo = this.reqNo;
				params.recommendTitle = this.recommendTitle;
				params.recommendPlace = this.recommendPlace;
				params.fileList = JSON.stringify(this.finalFileList);
				params.reqNail = this.$util.textFormat(this.reqNail);
				params.recommendType = this.recommendType;
				params.experienceIndex = this.experienceIndex;
				params.isShowConcact = this.isShowConcact ? '1' : '0';
				params.labelList = JSON.stringify(this.labelList);
				const initres = await postWithUInfo('/apis/modifyRecommendPost',params);
				if(initres.ec == '0'){
					vm.$util.hideMyLoading();
					this.$util.msg("修改成功",{},()=>{
						vm.$util.goBack();
					});
				}
			},
			goChooseClassfiy(){
				this.$router.push({
				  name: 'publishIndex',
				  query: {}
				});
			},
			handleCityChange(e){
				// console.log(e)
				this.queryCurrencySymbol();
			},
			async queryCurrencySymbol(){
				const initres = await queryUrlWithUInfo('/apis/queryCurrencySymbol',{'dqModel':JSON.stringify(this.areaId)});
				if(initres.ec == '0'){
					this.CurrencySymbol = initres.currencySymbol;
				}
			},
			addOrMulitiFileList(params){
				// console.log(params)
				if(params.type == '1'){
					this.finalFileList.push({fileId:params.file.fileId,path:params.file.path,url:params.file.path,thumbPath:params.file.thumbPath})
					// console.log(this.fileList)
				}else if(params.type == '2'){
					this.finalFileList = this.finalFileList.filter((item,index) => {
						return item.fileId != params.file.fileId
					});
					// console.log(this.fileList)
				}
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.radio_warp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		.radio_itm_warp {
			margin: 0px 10px 10px 0px;
		}
	}
	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;
		.headwarp {
			.head-item {
				width: 30%;
				text-align: left;
				line-height: 50px;
				border-bottom: 2px solid #F1F1F1;
			}
			.active {
				border-bottom: 2px solid #C88854;
			}
		}
		.cont-body {
			margin-top: 20px;
			//overflow: scroll;
			min-height: calc(100% - 100px);
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.ctx-item {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 10px 0;
					width: 600px;
					.label_hot_item {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						border: 1px solid #d9d9d9;
						background-color: #d9d9d9;
						margin-right: 10px;
						margin-bottom: 10px;
						padding: 0 14px;
						border-radius: 20px;
						cursor: pointer;
					}
					.bishu {
						color: #FF0000;
						margin-left: 4px;
					}
					.ctx-item-t {
						margin-right: 10px;
						.ctx-item-head {
							line-height: 40px;
							width: 160px;
							text-align: left;
							font-size: 14px;
							font-weight: 700;
						}
						.ctx-item-headdesc {
							font-size: 12px;
							text-align: left;
						}
					}
					.ctx-item-b {
						flex: 1;
						text-align:left;
						.hxs-warp {
							width: 100px !important;
							margin-right: 10px;
						}
						
					}
				}
			}
			.btn-foot {
				margin-top: 10px;
				.btn {
					width: 100px;
					margin: 0 auto;
					background-color: #c88854;
					color: #fff;
					padding: 10px 20px;
					border-radius: 30px;
					cursor: pointer;
				}
			}
		}
	}
</style>
